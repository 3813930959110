import { PillsRowStoryblok, PillStoryblok } from '@/shared/types/storyblok/storyblok-types';
import styles from './PillsRow.module.scss';
import Container from '../Container/Container';
import { StoryblokComponent } from '@storyblok/react';
import { motion, useScroll, useTransform, useAnimate, useDragControls } from 'framer-motion';
import { useRef, useState, useLayoutEffect } from 'react';

const PillsRow = ({ blok }: PillsRowStoryblok) => {
    const { rtl, pills, animation } = blok;
    const ref = useRef(null);
    const [scope] = useAnimate();
    const [elementWidth, setElementWidth] = useState(0);

    // TODO CHECK IF IT'S POSSIBLE TO MIX DRAG AND SCROLL HOOKS
    // const dragControls = useDragControls();

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['start start', 'end end'],
    });

    const translateX = useTransform(
        scrollYProgress,
        [0, 1],
        [rtl ? 0 : -1 * elementWidth, rtl ? -1 * elementWidth : 0],
    );

    useLayoutEffect(() => {
        if (scope.current) {
            setElementWidth(scope.current.offsetWidth);
        }
    }, [scope]);

    return (
        <div ref={ref}>
            <Container code="large" blok={blok} className={{ root: styles.root, wrap: styles.wrap }}>
                <div className={styles.layer} style={{ pointerEvents: 'none' }} />
                <motion.div
                    ref={scope}
                    style={{ x: animation === 'onScroll' ? translateX : undefined }}
                    className={styles.animWrap}

                    // drag="x"
                    // dragControls={dragControls}
                    // dragConstraints={{
                    //     right: rtl ? -1 * elementWidth : 0,
                    //     left: rtl ? 0 : -1 * elementWidth,
                    // }}
                >
                    {pills.map((item: PillStoryblok) => {
                        return <StoryblokComponent key={item._uid} blok={item} />;
                    })}
                </motion.div>
            </Container>
        </div>
    );
};

export default PillsRow;
